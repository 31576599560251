import SoundCloud from "@/components/Cliente/SoundCloud/SoundCloud.vue";
import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
    components: {
        SoundCloud,
        Banner
    },
    data() {
        return {
            arBreadcrumbs: [{
                    text: "Início",
                    href: "/"
                },
                {
                    text: "Áudios"
                }
            ],
            arCategorias: [],
            arAudios: [],
            blLoading: true,
            idCategoria: 0,
            rolagemMenu: false
        }
    },
    created() {
        this.listar();
        window.addEventListener("scroll", this.esconderMenuLateral);
    },
    methods: {
        listar() {
            this.$root.$api.get('audios/listar').then(
                (response) => {
                    this.arAudios = response.retorno.audios;
                    this.arCategorias = response.retorno.categorias;
                    this.blLoading = false;
                }
            )
        },
        esconderMenuLateral() {
            this.rolagemMenu = window.scrollY > 100 ? "block" : "none";
        }
    },
    computed: {
        audios() {
            return this.arAudios;
        }
    },
}